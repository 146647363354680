<template>
    <el-main>
        <el-form label-width="200px" :model="form" :rules="rules" ref="form">
            <el-tabs v-model="tab_active" @tab-click="tabClick">
                <el-tab-pane name="price" label="关键词价格">
                    <template v-if="tab_active=='price'">
                        <el-form-item label="关键词设置：" prop="is_pay">
                            <el-switch v-model="form.is_pay" :active-value="1" :inactive-value="0"></el-switch>
                            <span class="tips">用户每次点击关键词广告进行跳转消耗费用，关闭则表示不需要付费</span>
                        </el-form-item>
                        <el-form-item label="单次点击消耗费用：" v-if="form.is_pay" prop="click_cost">
                            <el-input size="small" placeholder="请输入单次点击消耗费用" v-model="form.click_cost"></el-input>
                            <span class="tips">元/次</span>
                        </el-form-item>
                        <el-form-item label="关键词展示基础费用：" v-if="form.is_pay" prop="basic_cost">
                            <el-input size="small" placeholder="请输入关键词展示基础费用" v-model="form.basic_cost"></el-input>
                            <span class="tips">元/天</span>
                        </el-form-item>
                    </template>
                </el-tab-pane>
                <el-tab-pane name="explains" label="关键词说明">
                    <el-form-item label="关键词说明：" prop="explains" v-if="tab_active=='explains'">
                        <RichText :richTxt="form.explains" @soninfo="val => (form.explains = val)"></RichText>
                    </el-form-item>
                </el-tab-pane>
            </el-tabs>
        </el-form>
        <Preservation @preservation="save"></Preservation>
    </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import RichText from '@/components/richText';
export default {
    components:{
        Preservation,
        RichText
    },
    data() {
        return {
            tab_active:'price',
            form:{
                is_pay:0,
                explains:'',
                click_cost:'',
                basic_cost:''
            },
            rules:{
                click_cost:[{
                   required: true, message: '请输入单次点击消耗费用',trigger: 'blur'
                }],
                basic_cost:[{
                   required: true, message: '请输入关键词展示基础费用',trigger: 'blur'
                }],
                explains:[{
                   required: true, message: '请输入关键词说明',trigger: 'blur'
                }]
            }
        };
    },
    created() {
        this.getInfo();
    },
    methods:{
        getInfo(){
            this.$axios.post(this.$api.gateway.keywords.setInfo)
            .then(res => {
                if (res.code == 0) {
                    this.form = res.result
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        save(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let url = ''
                    let data = {}
                    if(this.tab_active=='price'){
                        url = this.$api.gateway.keywords.editPrice
                        data.is_pay = this.form.is_pay
                        if(data.is_pay){
                            data.click_cost = this.form.click_cost
                            data.basic_cost = this.form.basic_cost
                        }
                    }else{
                        url = this.$api.gateway.keywords.editExplains
                        data.explains = this.form.explains
                    }
                    this.$axios.post(url,data)
                    .then(res => {
                        if (res.code == 0) {
                            this.$message.success("保存成功");
                            this.getInfo()
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .el-main {
        background: #fff;
        min-height: 100%;
        .tips{
            color: #888888;
            margin-left: 20px;
        }
        .el-input{
            width: 600px;
        }
    }
</style>
